"use strict";

module.exports = class GetLocationModel {
  request() {
    return (
      fetch(`${$AUTH_HOST}/v1/auth/location`, {
        headers: {
          'Accept': 'application/json'
        },
        method: 'GET',
        mode: 'cors'
      })
    );
  }
}
