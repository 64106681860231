"use strict";

const GetLocationModel = require('../../../js/info-localization/getLocationModel');

(function () {
  const gcc = ["BH", "KW", "OM", "QA", "SA", "AE"];
  new GetLocationModel().request()
    .then(res => res.json())
    .then(data => {
      const { countryCode } = data;
      if (countryCode && gcc.includes(countryCode)) {
        // window.location.replace("https://amzscout.net/report-deals ");
      }
    }).catch(err => {
      console.warn(err);
  });
}());
